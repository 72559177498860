@use 'variables' as v;
html{
    font-size: 62.5%;
    box-sizing: border-box;
}
*,
*::before,
*::after{
    box-sizing: inherit;
}
body{
    font-family: v.$fuente_principal;
    font-size: 1.6rem;
    &.fijar-body{
        overflow: hidden;
    }
    &.body-scroll{
        padding-top: 8.6rem;
    }

}
p{
    font-size: 2rem;
    color: v.$negro;
    line-height: 1.5;
}
.contenedor{
    width: 95%;
    max-width: 130rem;
    margin: 0 auto;
}
a{
    text-decoration: none;
}
h1, h2, h3{
    margin: 0 0 5rem 0;
}
h1{
    font-size: 4rem;
}
h2{
    font-size: 3.6rem;
    font-family: 'Franklin Gothic Medium';

}
h3{
    font-size: 3rem;
    font-family: 'Franklin Gothic Medium';
}
img{
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
}