@use 'base/variables' as v;
@use 'base/mixins' as m;

.header {
    background-color: #4cb8b3;

    @include m.tablet {
        &.fijo {
            left: 0;
            top: 0;
            position: fixed;
            width: 100%;
            box-shadow: .1rem .2rem .3rem #333;
        }
    }

    .contenido-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        @include m.tablet {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;

        @include m.tablet {
            flex-direction: row;
            margin-bottom: 0;
        }

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        img {
            width: 9rem;
            margin-right: 1rem;
        }

        span {
            color: v.$blanco;
            font-family: v.$fuente_principal;
            font-size: 1.8rem;
            font-weight: bold;
            text-align: center;
        }
    }

    .navegacion-principal {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include m.tablet {
            flex-direction: row;
            gap: 5rem;
        }

        a {
            text-align: center;
            color: v.$blanco;
            font-family: v.$fuente_principal;
            font-size: 2rem;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                color: v.$azul;
            }
        }
    }
}
