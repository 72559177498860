@use 'base/variables' as v;
@use 'base/mixins' as m;

.sobre-festival {
    padding: 5rem 2rem;
    margin-top: 3rem;
    background: linear-gradient(to right, #f0f0f0, #ffffff);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    @include m.tablet {
        @include m.grid(2, 5rem);
    }

    .imagen {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

        img {
            width: 100%;
            height: auto;
            border-radius: 10px;
            transition: transform 0.3s ease;
            
            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .contenido-festival {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 5rem;
        text-align: center;

        @include m.tablet {
            margin-top: 0;
            text-align: left;
        }

        h2 {
            font-size: 3rem;
            color: v.$coral;
            margin-bottom: 2rem;
            position: relative;

            &::after {
                content: '';
                width: 5rem;
                height: 0.4rem;
                background-color: v.$rosa;
                position: absolute;
                bottom: -1rem;
                left: 50%;
                transform: translateX(-50%);

                @include m.tablet {
                    left: 0;
                    transform: none;
                }
            }
        }

        p {
            color: #555555;
            font-size: 1.6rem;
            line-height: 1.8;
            margin-bottom: 2rem;
            padding: 0 1rem;

            &:first-of-type {
                font-style: italic;
            }

            @include m.tablet {
                padding: 0;
            }
        }

        .fecha {
            color: v.$rosa;
            font-weight: 700;
        }
    }
}
