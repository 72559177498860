@use 'base/mixins' as m;
@use 'base/variables' as v;

.boletos {
    padding: 1rem 0;

    .contenedor-contacto {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include m.tablet {
            flex-direction: row;
            justify-content: space-between;
        }

        .contenedor-contactos,
        .redesSociales {
            width: 100%;
            padding: 2rem;

            @include m.tablet {
                width: 45%;
            }
        }

        .contenedor-contactos {
            a {
                display: flex;
                align-items: center;
                color: v.$blanco;
                font-size: 1.6rem;
                padding: 2rem 0;
                font-weight: 700;
                text-decoration: none;

                span {
                    font-weight: 400;
                    margin-left: 3rem;
                }

                i {
                    font-size: 2.4rem;
                    position: absolute;
                    left: 1rem;
                    min-width: 3rem;
                    text-align: center;
                }

                &:hover {
                    color: v.$amarillo;
                }
            }
        }

        .redesSociales {
            h3 {
                color: v.$blanco;
                text-align: center;
                margin-bottom: 2rem;
            }

            .social_box {
                text-align: center;

                a {
                    color: v.$blanco;
                    font-size: 3rem;
                    margin: 1.5rem;
                    text-decoration: none;
                }

                i:hover {
                    color: v.$amarillo;
                }
            }
        }

        .contactoTitulo {
            margin-bottom: 1.5rem;
            color: v.$blanco;
            text-align: center;

            @include m.tablet {
                text-align: left;
            }
        }
    }
}
