//Tipografia
$fuente_principal: 'Montserrat', sans-serif;

//Colores
$verde: #4CB8B3;
$rosa: #F53756;
$morado: #752F97;
$negro: #000000;
$negroClaro: #454546;
$blanco: #FFFFFF;
$blancoFantasma:#F7F5FB;
$amarillo: #E08709;
$amarillo2:#fef46d;
$naranjaClaro: #F9AB55;
$GrisCool:#dcdcdc;
$azul:#084887;
$coral:#8B0000;

//Colores




//tamaños de pantalla
$telefono: 480px;
$tablet: 768px;
$desktop: 992px;
$desktopXL: 1200px;