@use 'base/mixins' as m;
@use 'base/variables' as v;

.galeria {
    padding: 5rem 0;
    
    h3 {
        text-align: center;
    }
}

.galeria-imagenes {
    @include m.resetear-lista;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

    @include m.telefono {
        grid-template-columns: repeat(3, 1fr);
    }
    @include m.tablet {
        grid-template-columns: repeat(4, 1fr);
    }
    @include m.desktop {
        grid-template-columns: repeat(6, 1fr);
    }
}

.galeria-imagenes picture {
    position: relative;
    overflow: hidden;
    aspect-ratio: 1 / 1; // Cuadrado
}

.galeria-imagenes img {
    width: 100%;
    height: 100%;
    object-fit: cover; // Ajusta la imagen para llenar el contenedor
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.overlay picture {
    display: block;
    max-width: 80rem;
    width: 100%;
}

.btn-cerrar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: v.$blanco;
    color: v.$negro;
    display: grid;
    place-items: center;
    font-weight: 700;
    &:hover {
        cursor: pointer;
    }
}
