@use 'base/variables' as v;
@use 'base/mixins' as m;

.fondo{
    position: relative;
    max-height: 83rem;
   
    overflow: hidden;

    @include m.tablet{
        min-height: 50rem;
    }

    .overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  
}
.contenido-video{
   z-index: 1;
    h2{
        color: v.$blanco;
        font-size: 7rem;
        margin-bottom: 0;
    }
    p{
        
        display: inline-block;
        padding: 1rem 2rem;
        color: v.$blanco;
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0;
    } 
    .box{
        margin-top: 2rem;
    }
    .boton1{
        background-color: v.$amarillo;
        border: none;
        color: #fff;            
        font-weight: 700;
        margin-top: 2.5rem;
        padding: 1rem 3rem;
        text-align: center;
        text-decoration: none;
    
        &:hover{
            background-color: darken(v.$amarillo,5);
            cursor: pointer;
        }
    }
    .boton2{
        background-color: v.$negro;
        border: none;
        color: #fff;            
        font-weight: 700;
        margin-top: 2.5rem;
        padding: 1rem 3rem;
        text-align: center;
        text-decoration: none;
    
        &:hover{
            background-color: darken(v.$negro,5);
            cursor: pointer;
        }
    
    } 
}
