@use 'base/variables' as v;
@use 'base/mixins' as m;

.lineupInfo{
    background-color: v.$blancoFantasma;
    padding: 5rem 0;
    
    h3, p{
        color: v.$negro;
        text-align: center;
    }
    .dia{
        text-transform: uppercase;
        margin: 1rem 0;
    }
}

.escenarios-contenedor{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    @include m.tablet {
        @include m.grid(2, 5rem)
    }

    .nombre-escenarioInfo{
        color: v.$negro;
        font-weight: bold;
    }
}
.escenario{
   // padding-top: 2rem;
    
    img {      
        width: 100%; /* Define el ancho máximo que desees */
        height: 400px; /* Define la altura máxima que desees */
        object-fit: cover; /* Asegura que la imagen mantenga su proporción y cubra el contenedor */
    }

    .nombre-escenarioInfo{
        
        &:after{
            content: '';
            display: block;
            width: 20rem;
            height: 1rem;
            background-size: 15rem;
            background-repeat: no-repeat;
            background-position: center center;
           // margin: 5rem auto 0 auto;
        }
    }
    .titulo-escenario{
        padding: 2rem  0 0 0;
        margin: 0;
        color: v.$negro;
        
    }
}
//.rock .nombre-escenario:after{
//    background-image: url(../img/icono_rock.png);
//}
//.edm .nombre-escenario:after{
//    background-image: url(../img/icono_edm.png);
//}
.calendario {
    @include m.resetear-lista;
    li{
        padding: 2rem;
        font-weight: 700;
        box-sizing: border-box; /* Incluye padding y border en el ancho total */
        width: 100%; /* Asegura que li ocupe el ancho completo del contenedor */
        overflow-wrap: break-word; /* Ajusta el texto largo dentro del contenedor */
        span{
            font-weight: 400;
        }

        a{
            color: v.$azul;
            a {
            display: block; /* Asegura que el enlace ocupe todo el ancho del contenedor */
            overflow-wrap: break-word; /* Permite que el texto largo se ajuste */
            box-sizing: border-box; /* Incluye padding y border en el ancho total */
            text-decoration: none;
            color: inherit; /* Hereda el color del contenedor, puedes ajustar esto según sea necesario */
        }

            &:hover{
                color: v.$azul;
            }
        }
    }
}
.bg-amarillo{
    background-color: v.$amarillo2;
    li:nth-child(odd){
        background-color: darken(v.$amarillo2, 10%);
    }
}
.bg-verde{
    background-color: v.$verde;
    li:nth-child(odd){
       background-color: darken(v.$verde, 10%);
    }
}
