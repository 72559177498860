@use 'base/variables' as v;

.site-footer{
    background-color: darkcyan;
    padding: 1rem;
    p{
        text-align: center;
        color: v.$blanco;
        margin: 0;
        
    }
}