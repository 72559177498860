@use 'base/variables' as v;
@use 'base/mixins' as m;

.lineup{
    background-color: v.$rosa;
    padding: 5rem 0;
    
    h3, p{
        color: v.$negro;
        text-align: center;
    }
    .dia{
        text-transform: uppercase;
        margin: 5rem 0;
    }
}

.escenarios-contenedor{
    display: flex;
    flex-direction: column;
    @include m.tablet {
        @include m.grid(2, 5rem)
    }

    .nombre-escenario{
        color: v.$blanco;
    }
}
.escenario{
   // padding-top: 2rem;
    
    img {      
        width: 100%; /* Define el ancho máximo que desees */
        height: 400px; /* Define la altura máxima que desees */
        object-fit: cover; /* Asegura que la imagen mantenga su proporción y cubra el contenedor */
    }

    .nombre-escenario{
        
        &:after{
            content: '';
            display: block;
            width: 20rem;
            height: 1rem;
            background-size: 15rem;
            background-repeat: no-repeat;
            background-position: center center;
           // margin: 5rem auto 0 auto;
        }
    }
    .titulo-escenario{
        padding: 2rem  0 0 0;
        margin: 0;
        color: v.$negro;
        
    }
}
//.rock .nombre-escenario:after{
//    background-image: url(../img/icono_rock.png);
//}
//.edm .nombre-escenario:after{
//    background-image: url(../img/icono_edm.png);
//}
.calendario {
    @include m.resetear-lista;
    li{
        padding: 2.5rem;
        font-weight: 700;
        span{
            font-weight: 400;
        }
    }
}
.bg-amarillo{
    background-color: v.$amarillo2;
   // li:nth-child(odd){
   //     background-color: darken(v.$amarillo, 10%);
    //}
}
.bg-verde{
    background-color: v.$verde;
   // li:nth-child(odd){
     //   background-color: darken(v.$negroClaro, 10%);
   // }
}
